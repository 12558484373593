<template>
  <div>
    <h3><b class="b">轮播内容</b><el-button class="button" @click="changeBannerText">新增</el-button></h3>
    <el-table :data="bannerInfoList" style="width: 100%">
      <el-table-column label="标题" prop="title"> </el-table-column>
      <el-table-column label="描述" prop="description"> </el-table-column>
      <el-table-column label="链接" prop="url"> </el-table-column>
      <el-table-column label="权重" prop="weightNum"> </el-table-column>
      <el-table-column label="置顶" prop="topFlag">
        <template slot-scope="scope">
          {{ scope.row.topFlag | topFlagFilter(topFlagList) }}
        </template> </el-table-column>
      <el-table-column label="指定背景图">
        <template slot-scope="scope">
          {{ scope.row.isRandomPic | isRandomPicFilter(randomPicList) }}
        </template>
      </el-table-column>
      <el-table-column label="背景图" prop="picId" width="180px">
        <template slot-scope="scope">
          <img v-if="scope.row.picId" :src="scope.row.picId | imgUrlFilter(bannerPicList)" alt="image">
        </template>
      </el-table-column>
      <el-table-column label="操作" width="160px">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" round @click="changeBannerText(scope.$index, scope.row)">
            修改
          </el-button>
          <el-button size="mini" type="primary" round @click="deleteBannerText(scope.$index, scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="轮播内容" :visible.sync="dialogInfoListVisible">
      <el-form :model="form">
        <el-form-item label="标题" :label-width="formLabelWidth">
          <el-input v-model="bannerTextInfo.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="描述" :label-width="formLabelWidth">
          <el-input v-model="bannerTextInfo.description" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="链接" :label-width="formLabelWidth">
          <el-input v-model="bannerTextInfo.url" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="权重" :label-width="formLabelWidth">
          <el-input v-model="bannerTextInfo.weightNum" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="置顶" :label-width="formLabelWidth">
          <el-select v-model="bannerTextInfo.topFlag" placeholder="请选择">
            <el-option v-for="(item, key) in topFlagList" :label="item.label" :value="item.value" :key="key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="指定背景图" :label-width="formLabelWidth">
          <el-select v-model="bannerTextInfo.isRandomPic" placeholder="请选择">
            <el-option v-for="(item, key) in randomPicList" :label="item.label" :value="item.value"
              :key="key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="bannerTextInfo.isRandomPic" label="背景图" :label-width="formLabelWidth">
          <el-select v-model="bannerTextInfo.picId" placeholder="请选择">
            <el-option v-for="(item, key) in bannerPicList" :label="item.id" :value="item.id" :key="key"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelBannerText">取 消</el-button>
        <el-button type="primary" @click="saveBannerText">确 定</el-button>
      </div>
    </el-dialog>

    <h3><b class="b">轮播图</b><el-button class="button" @click="changeBannerPic">新增</el-button></h3>
    <el-table :data="bannerPicList" style="width: 100%">
      <el-table-column label="id" prop="id"> </el-table-column>
      <el-table-column label="图片">
        <template slot-scope="scope">
          <img v-if="scope.row.url" :src="scope.row.url" class="bannerImg">
        </template>
      </el-table-column>
      <el-table-column label="权重" prop="weightNum"> </el-table-column>
      <el-table-column label="置顶">
        <template slot-scope="scope">
          {{ scope.row.topFlag | topFlagFilter(topFlagList) }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" round @click="changeBannerPic(scope.$index, scope.row)">
            修改
          </el-button>
          <el-button size="mini" type="primary" round @click="deleteBannerPic(scope.$index, scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="轮播图" :visible.sync="dialogListVisible">
      <el-form :model="bannerPicInfo">
        <el-form-item label="图片" :label-width="formLabelWidth">
          <el-upload class="avatar-uploader" :headers="{ token }" :show-file-list="false" list-type="picture-card"
            name="picFile" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
            action="/api/file/upload">
            <img v-if="bannerPicInfo.url" :src="bannerPicInfo.url" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="权重" :label-width="formLabelWidth">
          <el-input v-model="bannerPicInfo.weightNum" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="置顶" :label-width="formLabelWidth">
          <el-select v-model="bannerPicInfo.topFlag" placeholder="请选择">
            <el-option v-for="(item, key) in topFlagList" :label="item.label" :value="item.value" :key="key"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.stop="cancelBannerPic">取 消</el-button>
        <el-button type="primary" @click.stop="saveBannerPic">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getBannerPicApi,
  getBannerTextApi,
  saveBannerPicApi,
  deleteBannerPicApi,
  saveBannerTextApi,
  deleteBannerTextApi
} from '../../api/background'

const topFlagList = [{
  label: '是',
  value: 0
},
{
  label: '否',
  value: 1
}]

const randomPicList = [{
  label: '指定',
  value: true
},
{
  label: '随机',
  value: false
}]
export default {
  data() {
    return {
      topFlagList,
      randomPicList,
      form: {
        name: 123,
      },
      formbanner: {},
      formLabelWidth: '100',
      dialogListVisible: false,
      dialogInfoListVisible: false,
      bannerPicList: [],
      bannerInfoList: [],
      bannerPicInfo: {//编辑状态
        "id": "", //id
        "topFlag": 0, //是否置顶 0-否 1-是
        "url": "", //链接
        "weightNum": '10' //权重
      },
      bannerTextInfo: {//编辑状态
        "description": "", //描述
        "id": "", //id
        "picId": "", //背景图id
        "title": "", //标题
        "topFlag": 0, //是否置顶 0-否 1-是
        "url": "", //链接
        "weightNum": '10', //权重
        isRandomPic: false, // 是否指定背景图 ，自定义属性
      }
    }
  },
  created() {
    this.token = localStorage.getItem('TOKEN')
    this.getBannerPic()
    this.getBannerText()
  },
  methods: {
    changeBannerText(index, row) {
      this.dialogInfoListVisible = true
      if (row) {
        this.bannerTextInfo = JSON.parse(JSON.stringify(row));
      } else {
        Object.assign(this.$data.bannerTextInfo, this.$options.data().bannerTextInfo)
      }
    },
    changeBannerPic(index, row) {
      this.dialogListVisible = true
      if (row) {
        this.bannerPicInfo = JSON.parse(JSON.stringify(row));
      } else {
        Object.assign(this.$data.bannerPicInfo, this.$options.data().bannerPicInfo)
      }
    },
    cancelBannerPic() {
      this.dialogListVisible = false
      this.getBannerPic();
    },
    saveBannerPic() {
      if (!this.bannerPicInfo.url) {
        return this.$alert('请上传图片')
      }
      saveBannerPicApi(this.bannerPicInfo).then(res => {
        console.log('%c saveBannerPicApi....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
        this.cancelBannerPic()
      })
    },
    deleteBannerPic(index, row) {
      deleteBannerPicApi({ id: row.id }).then(res => {
        console.log('%c deleteBannerPicApi....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
        this.getBannerPic();
      })
    },
    cancelBannerText() {
      this.dialogInfoListVisible = false
      this.getBannerText();
    },
    saveBannerText() {
      if (!this.bannerTextInfo.title || !this.bannerTextInfo.description) {
        return this.$alert('请填写标题和描述')
      }
      saveBannerTextApi(this.bannerTextInfo).then(res => {
        console.log('%c saveBannerTextApi....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
        this.cancelBannerText()
      })
    },
    deleteBannerText(index, row) {
      console.log(index, row)
      deleteBannerTextApi({ id: row.id }).then(res => {
        console.log('%c deleteBannerTextApi....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
        this.getBannerText();
      })
    },

    getBannerPic() {
      getBannerPicApi().then(res => {
        console.log('%c getBannerPicApi....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
        this.bannerPicList = res.data
      })
    },
    getBannerText() {
      getBannerTextApi().then(res => {
        console.log('%c getBannerTextApi....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
        this.bannerInfoList = res.data.map(item => {
          return {
            ...item,
            isRandomPic: !!item.picId
          }
        })
      })
    },
    handleAvatarSuccess(res, file) {
      console.log('%c handleAvatarSuccess....', 'color: red; font-style: italic', res, file)
      this.bannerPicInfo.url = res.data
    },
    beforeAvatarUpload(file) {
      const isJPG = ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
      }
      return isJPG && isLt2M;
    },

  },
  filters: {
    topFlagFilter(topFlag, topFlagList) {
      return topFlagList.filter(item => item.value == topFlag)[0]?.label
    },
    isRandomPicFilter(bool, list) {
      return list.filter(item => item.value == bool)[0]?.label
    },
    imgUrlFilter(picId, List) {
      return List.filter(item => item.id == picId)[0]?.url
    }
  },
  watch: {
    'bannerTextInfo.isRandomPic'() {
      if (!this.bannerTextInfo.isRandomPic) {
        this.bannerTextInfo.picId = ''
      }
    }
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

img {
  max-height: 60px;
  min-width: 120px;
}

.button {
  margin-left: 30px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.b{
  font-weight: 700;
}
</style>
