<template>
  <el-table :data="needList" style="width: 100%">
    <el-table-column label="项目类型">
      <template slot-scope="scope">
        {{ scope.row.businessId | businessTypeFilter }}
      </template>
    </el-table-column>
    <el-table-column label="客户电话" prop="phoneNumber"> </el-table-column>
    <el-table-column label="用户ID" prop="userId"> </el-table-column>
    <el-table-column label="发布时间" prop="createTime">
      <template slot-scope="scope">
        {{ scope.row.createTime && scope.row.createTime | formatTimeFilter }}
      </template>
    </el-table-column>
    <el-table-column label="操作">
      <template slot-scope="scope">
        <span v-if="scope.row.isDealed"> 已处理</span>
        <el-button v-else size="mini" type="primary" round @click="projectIntentionDeal(scope.$index, scope.row)">
          标为已处理
        </el-button>
      </template>
    </el-table-column>
  </el-table>
  <!-- "id": 5,
    "businessId": 1,
    "phoneNumber": "13669236496",
    "userId": 2,
    "isSelfSubmit": 3,
    "recommenderUserId": 1,
    "isUserRecommend": 4,
    "recommenderPartnerName": "y有有有有有有有有有有有有有有有",
    "isPartnerRecommend": 2,
    "createTime": "2020-11-09T14:54:46.000+00:00",
    "isDealed": 0 -->
</template>

<script>
import {projectIntentionDealApi} from '../../api/background'

export default {
  props: ['needList', 'type'],
  methods: {
    projectIntentionDeal(index, row) {
      console.log(index, row)
      projectIntentionDealApi({id: row.id, isDealed: '1'}).then(res => {
        console.log('%c 变更状态，标记处理结果....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
        this.$emit('refreshNeedList')
      })
    }
  }
}
</script>

<style scoped></style>
